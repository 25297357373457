
















import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--primarycolor-dark)';

@Component
export default class Like extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;

}
