











































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import SendDoubtsLoading from '@/components/Doubts/SendDoubts/SendDoubtsLoading.vue';
import ReplyDoubtLoading from './components/ReplyDoubt/ReplyDoubtLoading.vue';

@Component({
  components: {
    ExLoading,
    SendDoubtsLoading,
    ReplyDoubtLoading,
  },
})
export default class ShowDoubtsLoading extends Vue {
  private quantityLoading = 2;
}
