import { render, staticRenderFns } from "./index.vue?vue&type=template&id=245ecfc9&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.scss?vue&type=style&index=0&id=245ecfc9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245ecfc9",
  null
  
)

export default component.exports