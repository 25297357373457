








































import { Component, Vue, Prop } from 'vue-property-decorator';

import Like from '@/assets/icons/Like.vue';
import Deslike from '@/assets/icons/Deslike.vue';

import HistoricService from '@/services/Historic/historicService';

const ACTIONS = {
  LIKE: 'like',
  DISLIKE: 'dislike',
  DELETE: 'delete',
};

@Component({
  components: {
    Like,
    Deslike,
  },
})
export default class Actions extends Vue {
  @Prop({ default: false }) userLiked!: boolean;
  @Prop({ default: false }) userDisliked!: boolean;
  @Prop({ default: false }) doubtFromUser!: boolean;
  @Prop({ default: 0 }) quantityLikeProp!: number;
  @Prop({ default: 0 }) quantityDislikeProp!: number;
  @Prop({ required: true }) doubtId!: number;

  private actions = ACTIONS;
  private like = this.userLiked;
  private dislike = this.userDisliked;
  private quantityLike = this.quantityLikeProp;
  private quantityDislike = this.quantityDislikeProp;

  private HistoricService = new HistoricService();

  async handleClickOnAction(userAction: string) {
    if (this.doubtFromUser) return;

    try {
      const newAction = this.setAction(userAction);

      this.updateActionLocal(newAction, userAction);

      const data = {
        action: newAction,
        doubtID: this.doubtId,
      };

      await this.HistoricService.saveActionDoubt(data);
    } catch (error) {
      this.resetAction();

      console.error(error);

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar ação.',
        status: 'error',
      });
    }
  }

  setAction(action: string) {
    if (this.like && action === this.actions.LIKE) return this.actions.DELETE;

    if (this.like && action === this.actions.DISLIKE) return this.actions.DISLIKE;

    if (this.dislike && action === this.actions.DISLIKE) return this.actions.DELETE;

    if (this.dislike && action === this.actions.LIKE) return this.actions.LIKE;

    return action;
  }

  updateActionLocal(newAction: string, userAction: string) {
    if (newAction === this.actions.LIKE) {
      this.like = true;
      this.dislike = false;

      this.quantityLike += 1;
      this.quantityDislike = this.userDisliked ? this.quantityDislikeProp - 1 : this.quantityDislikeProp;
      return;
    }

    if (newAction === this.actions.DISLIKE) {
      this.like = false;
      this.dislike = true;

      this.quantityDislike += 1;
      this.quantityLike = this.userLiked ? this.quantityLikeProp - 1 : this.quantityLikeProp;
      return;
    }

    if (userAction === this.actions.LIKE) {
      this.quantityLike -= 1;
    }

    if (userAction === this.actions.DISLIKE) {
      this.quantityDislike -= 1;
    }

    this.like = false;
    this.dislike = false;
  }

  resetAction() {
    this.like = this.userLiked;
    this.dislike = this.userDisliked;

    this.quantityLike = this.quantityLikeProp;
    this.quantityDislike = this.quantityDislikeProp;
  }
}

